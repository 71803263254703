

.header{
    background-image: url('../images/spacegamer.jpeg');
    height: 870px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

.empresas{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 60px;
    margin-bottom: 50px;
}

.logo{
    width: 110px;
    height: 110px;
    border-radius: 100%;
    border: 4px solid #8833ab;
    margin: 0 5px;
    margin-bottom: 10px;
}

.boas-vindas{
    margin-top: -70px !important;
    background-color: #ffff;
    width: 90%;
    margin: 0 auto;
    font-weight: 700;
    color: #8833ab;
    border: 4px solid #8833ab;
    padding: 10px;
    border-radius: 10px;
    font-family: "Source Sans 3", sans-serif;
    text-align: center;
}

.solicitar-atendimento-modal{
    margin: 75px auto;
    margin-top: 50px;
    margin-bottom: 80px;
    background-color: #ffffff;
    width: 80%;
    border-radius: 10px;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 4px solid #8833ab;
}



.btn-space {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.sem-numero{
    font-size: 11px;
    margin-top: -10px;
}

.digite{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 15px;
}

.digite p{
    font-size: 15px;
}

 .principal{
    padding-top: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -130px;
}

 .empresas{
    height: 210px;
    padding-top: 20px;
 }

.digite input{
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    background-color: #d1d1d1;
    width: 60px;
    height: 30px;
    padding-left: 5px;
    text-align: center;
}
.digite1 textarea{
    margin-top: -15px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    background-color: #d1d1d1;
    width: 320px;
    height: 170px;
    resize: none;
    padding-left: 5px;
    padding: 5px;
}

.digite1{
    margin-top: 15px;
}

.digite1 p{
    
    font-size: 15px;
}


.div-btn{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.div-btn button{
    width: 120px;
}

.fazer-pedido{
    padding: 5px;
    border-radius: 10px;
    border: none;
    background-color: rgb(18, 235, 18);
    color: #000000;
    font-weight: bold;
    cursor: pointer;
    font-weight: bolder;
}

.nao-fazer-pedido{
    padding: 5px;
    border-radius: 10px;
    border: none;
    background-color: rgb(174, 175, 174);
    color: #000000;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
    font-weight: bolder;
}
h3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}




  div.p{
    text-align: center;
    height: 360px;
  }

 
 
 
.div-btn{
   gap: 20px;
}

.div-btn button{
    width: 150px;
    height: 55px;
    font-weight: bolder;
}

footer{
    margin: 0 auto;
    background-color: #ffff;
    padding: 12px;
    text-align: center;
}

    /* @media (max-width: 390px) {
        .logo{
            width: 90px;
            height: 90px;
            margin-bottom: 30px;
        }
    }

    @media (max-width: 330px) {
        .logo{
            width: 80px;
            height: 80px;
            margin-bottom: 30px;
        }
    }
 */
 @media (max-width: 418px) {
    .digite1 textarea{
        width: 90%;
    }
}

@media (max-width: 420px) {
    .logo{
        width: 95px;
        height: 95px;
        margin-top: 10px;
        margin-bottom: -20px;
    }
}

